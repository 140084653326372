import React, { Component } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function ScheduleServiceSection(){

	const { t } = useTranslation();
	const history = useHistory();
	const handleSubmit = async (event) => {
    event.preventDefault();

    // Fetch API to submit the form data
    try {
      const response = await fetch('https://swissemail.vercel.app/api/schedule', {
        method: 'POST', // You may want to change the method depending on your server's requirements
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
		  date: new Date(),
          name: event.target.elements.name.value,
          email: event.target.elements.email.value,
          phone: event.target.elements.phone.value,
          service: event.target.elements.service.value,
          message: event.target.elements.message.value,
        }),
      });

	  if (response.ok) {
		// Successful submission
		//console.log("Form submitted successfully");
		alert("Form submitted")
		event.target.reset();
		history.push('/thankyou');
		// Reset the form or perform other actions
	  } else {
		// Handle errors
		console.error("Form submission failed");
	  }

      // Handle the response as needed
      //console.log('Form submitted successfully', response);
    } catch (error) {
      //console.error('Error submitting form', error);
	  
    }


	/* const formData = new FormData(event.target);

    // Send the data to the Google Apps Script
    const response = await fetch(
      "https://script.google.com/macros/s/AKfycbyU_GwGzwG5Ng8sxC93-NzyMv5nDAGODew9FubY1o8Yqtkh1vVHR2M4HCoRv4VSNXyj2Q/exec",
      {
        method: "POST",
        body: formData,
      }
    ); */

    // Handle the response if needed
    /* if (response.ok) {
      // Successful submission
      console.log("Form submitted successfully");
	  alert("Form submitted")
      event.target.reset();
	  history.push('/thankyou');
      // Reset the form or perform other actions
    } else {
      // Handle errors
      console.error("Form submission failed");
    } */

  };
		return(
			<>
			<section className="section-area bg-primary form-card">
			<div className="container">
				<div className="row align-items-center">
					<div className="col-lg-3 align-self-center">
						<div className="form-head text-white mb-md-30">
							<h3 className="title">{t('home-schedule-section.title')}</h3>
							<h6 className="mb-0"><span className="fw4 mr-1">{t('home-schedule-section.call')}&nbsp;</span><span dir="ltr">+966 92 001 5259</span>
</h6>
						</div>
					</div>
					<div className="col-lg-9">
						<form onSubmit={handleSubmit} >
							<div className="row sp5">
								<div className="col-md-4 col-sm-6">
									<div className="form-group">
										<input type="text" className="form-control" placeholder={t('home-schedule-section.name')} name="name" required/>
									</div>
								</div>
								<div className="col-md-4 col-sm-6">
									<div className="form-group">
										<input type="text" className="form-control" placeholder={t('home-schedule-section.email')} name='email' required/>
									</div>
								</div>
								<div className="col-md-4 col-sm-6">
									<div className="form-group">
										<input type="text" className="form-control" placeholder={t('home-schedule-section.phone')} name='phone' required/>
									</div>
								</div>
								<div className="col-md-4 col-sm-6">
									<div className="form-group">
										<select className="form-control" name='service' required>
													<option value="" selected="selected">{t('home-schedule-section.choose')}</option>
													<option value="Axle/Drive Shaft Repair">{t('home-schedule-section.axle')}</option>
													<option value="Brake Repair">{t('home-schedule-section.brake')}</option>
													<option value="Engine Cooling System Repair">{t('home-schedule-section.engine-cooling')}</option>
													<option value="Gearbox Repair">{t('home-schedule-section.gearbox')}</option>
													<option value="Pre-Purchase Inspection">{t('home-schedule-section.pre-purchase')}</option>
													<option value="Suspension Repair">{t('home-schedule-section.suspension')}</option>
													<option value="Transmission Repair">{t('home-schedule-section.transmission')}</option>
													<option value="Touchless Wheel Alignment">{t('home-schedule-section.touchless-wheeel')}</option>
													<option value="AC Repair">{t('home-schedule-section.ac-repair')}</option>
													<option value="Camera / Radar Calibration">{t('home-schedule-section.camera')}</option>
													<option value="Car Software Repair & Programming">{t('home-schedule-section.car-software')}</option>
													<option value="Comfort System Repair">{t('home-schedule-section.comfort')}</option>
													<option value="Engine Control Unit Repair">{t('home-schedule-section.engine-control')}</option>
													<option value="Navigation Update">{t('home-schedule-section.navigation')}</option>
													<option value="Retrofit">{t('home-schedule-section.retrofit')}</option>
													<option value="Smart Body Repair">{t('home-schedule-section.smart-body')}</option>
													<option value="Car Detailing">{t('home-schedule-section.car-detailing')}</option>
													<option value="Car Tinting">{t('home-schedule-section.car-tinting')}</option>
													<option value="Car DIP Color">{t('home-schedule-section.car-color')}</option>
													<option value="Ceramic Coating">{t('home-schedule-section.ceramic-coating')}</option>
													<option value="Brake Caliper painting">{t('home-schedule-section.brake-caliper')}</option>
													<option value="Paint Protection Film">{t('home-schedule-section.paint-protection')}</option>
													<option value="WindShield Repair">{t('home-schedule-section.windshield')}</option>
													<option value="Classic Car Restoration">{t('home-schedule-section.classic-car')}</option>
													<option value="Car Recovery">{t('home-schedule-section.car-recovery')}</option>
													<option value="Fleet Management">{t('home-schedule-section.fleet')}</option>
													<option value="Electric Car Repair">{t('home-schedule-section.electric-car')}</option>
												</select>
									</div>
								</div>
								<div className="col-md-4 col-sm-6">
									<div className="form-group">
										<input type="text" className="form-control" name='message' placeholder={t('home-schedule-section.help')} required />
									</div>
								</div>
								<div className="col-md-4 col-sm-6">
									<div className="form-group">
										<button type="submit" className="btn btn-warning w-100">{t('home-schedule-section.call-back')}</button>
									</div>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
		</section>
			</>
		);
}

export default ScheduleServiceSection;