import React, { Component } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import parse from "html-react-parser";
import useFetchAboutInfo from "../lib/useFetchAboutInfo";

// Layout
import Header from "../layout/header";
import Footer from "../layout/footer";

// Elements
import AboutUsTwoSection from "../elements/about-us-2";
import ServicesSection from "../elements/services-1";
import ScheduleServiceSection from "../elements/schedule-service";
import TestimonialSection from "../elements/testimonial";
import LatestBlogSection from "../elements/latest-blog-slider";

// Images
import bnrImg from "../../images/banner/aboutus.jpg";



import { useEffect } from "react";
import axios from "axios";
import { useState } from "react";
import { Helmet } from "react-helmet";

function PrivacyPolicy() {
  const { t } = useTranslation();
  const { pdata, loading, error } = useFetchAboutInfo("privacy-policy");

  const titleText =    pdata?.data?.pageBy?.translation?.title || t("aboutpage.about-title");
  const contentText =    pdata?.data?.pageBy?.translation?.content || '<div className="AbtBx3"></div>';
  const metaText = "Privacy Policy | Royal Swiss Auto Services";
  const metaDesc = "Privacy Policy | Royal Swiss Auto Services";

  if (loading)
    return (
      <>
        <div className="loader-container"></div>
      </>
    );
  if (error) return <p>Error: {error.message}</p>;

  return (
    <>
      <Header seotitle={metaText} seodesc={metaDesc} />

      <div className="page-content bg-white">
        <div
          className="page-banner ovbl-dark"
          style={{ backgroundImage: "url(" + bnrImg + ")" }}
        >
          <div className="container">
            <div className="page-banner-entry text-center">
              <h1>
                <span>{titleText}</span>
              </h1>
              <nav aria-label="breadcrumb" className="breadcrumb-row">
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">
                      <i className="las la-home"></i>
                      {t("header-area.home")}
                    </Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    {titleText}
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>

        {/* }
        {parse(contentText)}
        { */}

        <div className=" AbtBx3">
          <div className="container">
            <div className="heading-bx">

            {parse(contentText)}
              
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}

export default PrivacyPolicy;
