import React, {Component, useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Button } from "react-bootstrap";

// Layout
import Header from "../layout/header";
import Footer from "../layout/footer";

// Images
import offer1 from "../../images/banner/30-off-on-labor.png";
import offer2 from "../../images/banner/free-safety-checkup.png";
import offer3 from "../../images/banner/summer-ac-offer.png";

import offer1en from "../../images/banner/20-off-on-service-contract-en.png";
import offer1ar from "../../images/banner/20-off-on-service-contract-ar.png";
import offer2en from "../../images/banner/car-ac-offer-en.png";
import offer2ar from "../../images/banner/car-ac-offer-ar.png";
import offer3en from "../../images/banner/engine-oil-offer-en.png";
import offer3ar from "../../images/banner/engine-oil-offer-ar.png";


import bnrImg from "../../images/banner/bnr5.jpg";
import blogGridPic1 from "../../images/blog/grid/pic1.jpg";
import blogGridPic2 from "../../images/blog/grid/pic2.jpg";
import blogGridPic3 from "../../images/blog/grid/pic3.jpg";
import blogGridPic4 from "../../images/blog/grid/pic4.jpg";
import blogGridPic5 from "../../images/blog/grid/pic5.jpg";
import blogGridPic6 from "../../images/blog/grid/pic6.jpg";
import authorThumbPic1 from "../../images/testimonials/pic1.jpg";
import authorThumbPic2 from "../../images/testimonials/pic2.jpg";
import authorThumbPic3 from "../../images/testimonials/pic3.jpg";
import authorThumbPic4 from "../../images/testimonials/pic4.jpg";
import authorThumbPic5 from "../../images/testimonials/pic5.jpg";
import authorThumbPic6 from "../../images/testimonials/pic6.jpg";

import HeroMainRow from '../elements/widget/HeroMainRow';
import ScheduleServiceSection from '../elements/schedule-service';
import TestimonialSection from '../elements/testimonial';
import OurBrandsLayout from '../elements/widget/OurBrandsLayout';
import ChooseUsSection from '../elements/choose-us';
import InstagramBox from '../elements/widget/InstagramBox';
import axios from 'axios';
import { Helmet } from 'react-helmet';
import BookingForm from '../elements/offerbookingform';
const content = [
	{ 
		thumb: blogGridPic1,
		authorThumb: authorThumbPic1,
		authorName: "Mark John",
		title: "Free Body Polish",
		blogText: "Download our app and get the free body polish done for your car. To know more about this offer just dial 800 79477. Car care made even more simpler with us!",
	},
	{ 
		thumb: blogGridPic2,
		authorThumb: authorThumbPic2,
		authorName: "Merry Desulva",
		title: "Make Your Auto Clean As Before Like New",
		blogText: "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration.",
	},
	{ 
		thumb: blogGridPic3,
		authorThumb: authorThumbPic3,
		authorName: "Thomas deo",
		title: "Interior Cleaning with a Steam Generator",
		blogText: "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration.",
	},
	{ 
		thumb: blogGridPic4,
		authorThumb: authorThumbPic4,
		authorName: "Merry Desulva",
		title: "Types of Portal Sinks and their Advantages",
		blogText: "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration.",
	},
	{ 
		thumb: blogGridPic5,
		authorThumb: authorThumbPic5,
		authorName: "Thomas deo",
		title: "Excellent Customer Service Car Repair",
		blogText: "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration.",
	},
	{ 
		thumb: blogGridPic6,
		authorThumb: authorThumbPic6,
		authorName: "Mark John",
		title: "Car Wash Types by Service Type",
		blogText: "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration.",
	},
    { 
		thumb: blogGridPic1,
		authorThumb: authorThumbPic5,
		authorName: "Thomas deo",
		title: "Excellent Customer Service Car Repair",
		blogText: "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration.",
	},
	{ 
		thumb: blogGridPic2,
		authorThumb: authorThumbPic6,
		authorName: "Mark John",
		title: "Car Wash Types by Service Type",
		blogText: "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration.",
	},
]

const offers = [
	{
	  id: 1,
	  title: "offerpage.offerTitle1",
	  description: "offerpage.offerdesc1",
	  imageEn: offer1en,
	  imageAr: offer1ar,
	},
	{
	  id: 2,
	  title: "offerpage.offerTitle2",
	  description: "offerpage.offerdesc2",
	  imageEn: offer2en,
	  imageAr: offer2ar,
	},
	{
	  id: 3,
	  title: "offerpage.offerTitle3",
	  description: "offerpage.offerdesc3",
	  imageEn: offer3en,
	  imageAr: offer3ar,
	},
  ];

function Offers(){
	
	const { t } = useTranslation();

	const [showModal, setShowModal] = useState(false);
  const [selectedOffer, setSelectedOffer] = useState(null);

  const handleShow = (offer) => {
    setSelectedOffer(offer);
    setShowModal(true);
  };

  const handleClose = () => {
    setShowModal(false);
  };

  const handleSubmit = (formData) => {
    console.log("Booking Submitted:", formData);
  };

		return (
			<>

				<Header />
				
				<div className="page-content bg-gray">
					
					<div className="page-banner ovbl-dark" style={{backgroundImage: "url("+bnrImg+")"}}>
						<div className="container">
							<div className="page-banner-entry text-center">
								<h1><span>{t('header-area.offers')}</span></h1>
								<nav aria-label="breadcrumb" className="breadcrumb-row">
									<ul className="breadcrumb">
										<li className="breadcrumb-item"><Link to="/"><i className="las la-home"></i>{t('header-area.home')}</Link></li>
										<li className="breadcrumb-item active" aria-current="page">{t('header-area.offers')}</li>
									</ul>
								</nav>
							</div>
						</div>
					</div>
                    
					
					<section className="section-area section-sp2 cstBg cstoffers">
					   <div className="container">
							<div className="row">

							{offers.map((offer) => (
								<div key={offer.id} className="col-md-4 col-xl-4">
								<div className="blog-card style-1 bg-white shadow offercard">
									<div className="post-media">
									<img className="en-offerhide" src={offer.imageEn} alt={t(offer.title)} />
									<img className="ar-offerhide" src={offer.imageAr} alt={t(offer.title)} />
									</div>
									<div className="post-info">
									<h4 className="post-title">
										<Link to="#" onClick={() => handleShow(offer)}>{t(offer.title)}</Link>
									</h4>
									<div className="post-content">
										<p className="mb-20">{t(offer.description)}</p>
										<Button className="SerT2 btn btn-primary" onClick={() => handleShow(offer)}>
											{t("offerpage.offerbutton")}
										</Button>
									</div>
									</div>
								</div>
								</div>
							))}
								

								{/*} Change this in the offercard
								<div className="post-info">
									<h4 className="post-title">
										<Link to="#" onClick={() => handleShow(offer)}>{t(offer.title)}</Link>
									</h4>
									<div className="post-content">
										<p className="mb-20">{t(offer.description)}</p>
										<Button className="SerT2 btn btn-primary" onClick={() => handleShow(offer)}>
										{t("offerpage.offerbutton")}
										</Button>
									</div>
								</div>

								{*/}
									

									

									

									{/*}
									<div className="col-md-4 col-xl-4 ">
										<div className="blog-card style-1 bg-white shadow offercard">
											<div className="post-media">
												<img className="en-offerhide" src={offer5en} alt={t('offerpage.offerTitle5')}/>
												<img className="ar-offerhide" src={offer5ar} alt={t('offerpage.offerTitle5')}/>
											</div>
											<div className="post-info">
												<h4 className="post-title"><Link to="/booking">{t('offerpage.offerTitle5')}</Link></h4>
												<div className="post-content">
													<p className="mb-20">{t('offerpage.offerdesc5')}</p>
													<Link className="SerT2 btn btn-primary" to="/booking">{t('offerpage.offerbutton')}</Link>
												</div>
											</div>
										</div>
									</div>
									{*/}

								
							</div>
							
						</div>
					</section>
                    <HeroMainRow/>
                    <ScheduleServiceSection/>

                    <TestimonialSection/>
                    <ChooseUsSection/>
                    <OurBrandsLayout/>
                    <InstagramBox/>
                                                
				</div>
				
				<Footer />
				
				<BookingForm show={showModal} handleClose={handleClose} selectedOffer={selectedOffer} onSubmit={handleSubmit} />
			</>
		);
}

export default Offers;