import React, { useState } from "react";
import axios from "axios";
import { Modal, Button, Form, Alert, Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useHistory } from 'react-router-dom';

const BookingForm = ({ show, handleClose, selectedOffer, onSubmit }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    email: "",
    carModel: "",
    offerTitle: selectedOffer ? t(selectedOffer.title) : "",
  });

  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const [error, setError] = useState(null);

  // Update the form field state
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setMessage(null);
    setError(null);

    //console.log(formData);

    try {
      const response = await fetch("https://swissemail.vercel.app/api/offersubmit", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      const result = await response.json();

      console.log(result);

      if (response.ok) {
        setMessage("Offer submitted successfully!");
        //onSubmit(formData); // Call the parent function
        //setFormData({ name: "", phone: "", email: "", carModel: "", offerTitle: selectedOffer ? t(selectedOffer.title) : "" });
        //setTimeout(() => handleClose(), 2000);
        history.push('/thankyou');
      } else {
        setError(result.message || "Something went wrong!");
      }
    } catch (err) {
      setError("Failed to submit. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  // Update offer title when selectedOffer changes
  React.useEffect(() => {
    if (selectedOffer) {
      setFormData((prev) => ({
        ...prev,
        offerTitle: t(selectedOffer.title),
      }));
    }
  }, [selectedOffer, t]);

  return (
    <Modal show={show} onHide={handleClose} centered dialogClassName="offerModalClass">
      <Modal.Header closeButton>
        <Modal.Title>{t('offerpage.offerbutton')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        
        
        {message && <Alert variant="success">{message}</Alert>}
        {error && <Alert variant="danger">{error}</Alert>}
        
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3 formOfferText">
            <Form.Label>Offer Selected</Form.Label>
            <Form.Control type="text" name="offerTitle" value={formData.offerTitle} readOnly />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Control type="text" name="name" value={formData.name} placeholder={t('bookingapp.name')} onChange={handleChange} required />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Control type="text" name="phone" value={formData.phone} placeholder={t('bookingapp.phone')} onChange={handleChange} required />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Control type="email" name="email" value={formData.email} placeholder={t('bookingapp.email')} onChange={handleChange} required />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Select name="carModel" value={formData.carModel} onChange={handleChange}>
                              <option value="" selected="selected">{t("offerpage.offercarmodel")}</option>
															<option value="Bentley">{t('bookingapp.Bentley')}</option>
															<option value="jaguar">{t('bookingapp.jaguar')}</option>
															<option value="range-rover">{t('bookingapp.range-rover')}</option>
															<option value="mini-cooper">{t('bookingapp.mini-cooper')}</option>
															<option value="rolls-Royce">{t('bookingapp.rolls-Royce')}</option>
															<option value="audi">{t('bookingapp.audi')}</option>
															<option value="bmw">{t('bookingapp.bmw')}</option>
															<option value="mercedes">{t('bookingapp.mercedes')}</option>
															<option value="porache">{t('bookingapp.porsche')}</option>
															<option value="volkswagen">{t('bookingapp.volkswagen')}</option>
															<option value="ferrari">{t('bookingapp.ferrari')}</option>
															<option value="lamborghini">{t('bookingapp.lamborghini')}</option>
															<option value="maserati">{t('bookingapp.maserati')}</option>
															<option value="cadillac">{t('bookingapp.cadillac')}</option>
															<option value="chevrolet">{t('bookingapp.chevrolet')}</option>
															<option value="dodge">{t('bookingapp.dodge')}</option>
															<option value="ford">{t('bookingapp.ford')}</option>
															<option value="gmc">{t('bookingapp.gmc')}</option>
															<option value="jeep">{t('bookingapp.jeep')}</option>
															<option value="infinitty">{t('bookingapp.infiniti')}</option>
															<option value="lexus">{t('bookingapp.lexus')}</option>
															<option value="pagani">{t('bookingapp.pagani')}</option>
															<option value="bugatti">{t('bookingapp.bugatti')}</option>
															<option value="mclaren">{t('bookingapp.mclaren')}</option>
															<option value="maybach">{t('bookingapp.maybach')}</option>
            </Form.Select>
          </Form.Group>
          <Button variant="primary" type="submit" className="w-100" disabled={loading}>
            {loading ? <Spinner animation="border" size="sm" /> : t('offerpage.offersubmit')}
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default BookingForm;

