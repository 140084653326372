import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import {Accordion} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

// Layout
import Header from "../layout/header";
import Footer from "../layout/footer";

// Images
import bnrImg from "../../images/banner/bnr3.jpg";
import bnrImg2 from "../../images/background/bg-map.png";

function LinkTreePage() {

    const { t } = useTranslation();
  
      return (
			<>
				
				<Header />
				
				<div className="page-content bg-white linktree">
					
					<div className="page-banner ovbl-dark" style={{backgroundImage: "url("+bnrImg+")"}}>
						<div className="container">
							<div class="row">
								<div class="col-md-6 offset-md-3 ltheaderText">{t("linktreepage.maintitle")}</div>
							</div>
						</div>						
					</div>
					
						
					<section className="section-area section-sp1" style={{backgroundImage: "url("+bnrImg2+")"}}>
						<div className="container">
							<div className="row">
								<div className="col-md-12 mb-30">
									<a href="https://wa.me/+966557835647?text=Hi%20Royal%20Swiss%20Auto%20Services%20%2C%20%0AI%20would%20like%20to%20know%20more%20about%20your%20services" target='_blank'>
										<div className="feature-container bg-gray-alt left feature-bx1 cstcolor1">
											<div className="icon-lg">
												<i class="lab la-whatsapp"></i>
											</div>
											<div className="icon-content pl-10">
												<h5 className="ttr-tilte">Whatsapp</h5>
											</div>
										</div>
									</a>
								</div>
								<div className="col-md-12 mb-30">
									<a href="https://www.instagram.com/swissautosa/" target='_blank'>
										<div className="feature-container bg-gray-alt left feature-bx1 cstcolor2">
											<div className="icon-lg">
												<i class="lab la-instagram"></i>
											</div>
											<div className="icon-content pl-10">
												<h5 className="ttr-tilte">Instagram</h5>
											</div>
										</div>
									</a>
								</div>
								<div className="col-md-12 mb-30">
									<a href="https://www.facebook.com/swissautosa/" target='_blank'>
										<div className="feature-container bg-gray-alt left feature-bx1 cstcolor3">
											<div className="icon-lg">
												<i class="lab la-facebook"></i>
											</div>
											<div className="icon-content pl-10">
												<h5 className="ttr-tilte">Facebook</h5>
											</div>
										</div>
									</a>
								</div>
								<div className="col-md-12 mb-30">
									<a href="tel:+966920015259" target='_blank'>
										<div className="feature-container bg-gray-alt left feature-bx1 cstcolor4">
											<div className="icon-lg">
												<i class="las la-mobile"></i>
											</div>
											<div className="icon-content pl-10">
												<h5 className="ttr-tilte" dir="ltr">+966 92 001 5259</h5>
											</div>
										</div>
									</a>
								</div>
								<div className="col-md-12 mb-30">
									<Link to="/">
										<div className="feature-container bg-gray-alt left feature-bx1 cstcolor5">
											<div className="icon-lg">
												<i class="las la-globe"></i>
											</div>
											<div className="icon-content pl-10">
												<h5 className="ttr-tilte">www.swissauto.me</h5>
											</div>
										</div>
									</Link>
								</div>

							</div>
						</div>
					</section>
						
				</div>
				
				<Footer />
				
			</>
		);

}

export default LinkTreePage;