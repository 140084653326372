import React, {Component, useState} from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Layout
import Header from "../layout/header";
import Footer from "../layout/footer";

// Elements
import WidgetContact from "../elements/widget/widget-contact";

// Images
import bnrImg from "../../images/banner/bnr2.jpg";

import bnrImgEn from "../../images/banner/hiring-en.jpg"; // English banner image
import bnrImgAr from "../../images/banner/hiring-ar.jpg"; // Arabic banner image

import JobApplicationForm from '../elements/job-app';

function JobCareer() {

	const { t, i18n } = useTranslation();

	const [selectedCountry, setSelectedCountry] = useState('riyadh'); // Default to 'saudi'

	// Content for Riyadh and UAE
	const riyadhContent = (
		<div className='careerContentDiv'>
		  <div className="row">
				<div className="col-md-6">
					<ul className="list-check">
						<li>{t('vacancyriyadh.List1')}</li>
						<li>{t('vacancyriyadh.List2')}</li>
						<li>{t('vacancyriyadh.List3')}</li>
						<li>{t('vacancyriyadh.List4')}</li>
						<li>{t('vacancyriyadh.List5')}</li>
						<li>{t('vacancyriyadh.List6')}</li>
						<li>{t('vacancyriyadh.List7')}</li>
						<li>{t('vacancyriyadh.List8')}</li>
						<li>{t('vacancyriyadh.List9')}</li>
						<li>{t('vacancyriyadh.List10')}</li>
						<li>{t('vacancyriyadh.List11')}</li>
						<li>{t('vacancyriyadh.List12')}</li>
						<li>{t('vacancyriyadh.List13')}</li>
						<li>{t('vacancyriyadh.List14')}</li>			
					</ul>
				</div>

				<div className="col-md-6">
					<ul className="list-check">						
						<li>{t('vacancyriyadh.List15')}</li>
						<li>{t('vacancyriyadh.List16')}</li>
						<li>{t('vacancyriyadh.List17')}</li>
						<li>{t('vacancyriyadh.List18')}</li>
						<li>{t('vacancyriyadh.List19')}</li>
						<li>{t('vacancyriyadh.List20')}</li>
						<li>{t('vacancyriyadh.List21')}</li>
						<li>{t('vacancyriyadh.List22')}</li>
						<li>{t('vacancyriyadh.List23')}</li>
						<li>{t('vacancyriyadh.List24')}</li>
						<li>{t('vacancyriyadh.List25')}</li>
						<li>{t('vacancyriyadh.List26')}</li>
						<li>{t('vacancyriyadh.List27')}</li>
						<li>{t('vacancyriyadh.List28')}</li>			
					</ul>
				</div>
		  </div>
		</div>
	  );
	
	  const jeddahContent = (
		<div className='careerContentDiv'>
		  <div className="row">
				<div className="col-md-6">
					<ul className="list-check">
						<li>{t('vacancyjeddah.List1')}</li>
						<li>{t('vacancyjeddah.List2')}</li>
						<li>{t('vacancyjeddah.List3')}</li>
						<li>{t('vacancyjeddah.List4')}</li>
						<li>{t('vacancyjeddah.List5')}</li>
						<li>{t('vacancyjeddah.List6')}</li>
						<li>{t('vacancyjeddah.List7')}</li>
						<li>{t('vacancyjeddah.List8')}</li>
						<li>{t('vacancyjeddah.List9')}</li>
						<li>{t('vacancyjeddah.List10')}</li>
						<li>{t('vacancyjeddah.List11')}</li>
						<li>{t('vacancyjeddah.List12')}</li>
						<li>{t('vacancyjeddah.List13')}</li>
						<li>{t('vacancyjeddah.List14')}</li>
						<li>{t('vacancyjeddah.List15')}</li>
						<li>{t('vacancyjeddah.List16')}</li>
						<li>{t('vacancyjeddah.List17')}</li>			
					</ul>
				</div>

				<div className="col-md-6">
					<ul className="list-check">						
						<li>{t('vacancyjeddah.List18')}</li>
						<li>{t('vacancyjeddah.List19')}</li>
						<li>{t('vacancyjeddah.List20')}</li>
						<li>{t('vacancyjeddah.List21')}</li>
						<li>{t('vacancyjeddah.List22')}</li>
						<li>{t('vacancyjeddah.List23')}</li>
						<li>{t('vacancyjeddah.List24')}</li>
						<li>{t('vacancyjeddah.List25')}</li>
						<li>{t('vacancyjeddah.List26')}</li>
						<li>{t('vacancyjeddah.List27')}</li>
						<li>{t('vacancyjeddah.List28')}</li>
						<li>{t('vacancyjeddah.List29')}</li>
						<li>{t('vacancyjeddah.List30')}</li>
						<li>{t('vacancyjeddah.List31')}</li>
						<li>{t('vacancyjeddah.List32')}</li>
						<li>{t('vacancyjeddah.List33')}</li>			
					</ul>
				</div>
		  </div>
		</div>
	  );
	
	  const handleCountryChange = (e) => {
		setSelectedCountry(e.target.value); // Update state on selection change
	  };

	  const backgroundImg = i18n.language === 'ar' ? bnrImgAr : bnrImgEn;

	  const careerBgClass = i18n.language === 'ar' ? 'careerbg-ar' : 'careerbg-en';
	
		return (
			<>
				
				<Header />
				
				<div className="page-content bg-white">
					
					<div className={`page-banner ovbl-dark ${careerBgClass}`} style={{backgroundImage: `url(${backgroundImg})`}}>
						<div className="container">
							<div className="page-banner-entry text-center">
								{/*}
								<h1><span>{t('career.page-title')}</span></h1>
								<nav aria-label="breadcrumb" className="breadcrumb-row">
									<ul className="breadcrumb">
										<li className="breadcrumb-item"><Link to="/"><i className="las la-home"></i>{t('header-area.home')}</Link></li>
										<li className="breadcrumb-item active" aria-current="page">{t('career.page-title')}</li>
									</ul>
								</nav>
								{*/}
							</div>
						</div>
					</div>
					
					<div className="section-area bg-gray section-sp1">
						<div className="container">
							<div className="row">
								<div className="col-lg-8 col-md-7 mb-md-20">
									{/*}
									<div className="heading-bx displayNone">
										<h6 className="title-ext text-primary displayNone">{t('career.page-title')}</h6>
										<h2 className="title mb-0">{t('career.sub-title')}</h2>
										<p className="head-px2 displayNone">{t('career.sub-text')}</p>
									</div>
									{*/}
									<div className="row">
										<div className="col-md-12 mb-30">
											<div className="job-career-box">

												<div className="heading-bx ">
													<h1 className="title mb-0">{t('career.sub-title')}</h1>
													<p className="head-px2 displayNone">{t('career.sub-text')}</p>
												</div>

												<div className='careerListDiv'>
													<h4>
														{t('career.loc')}

														<select onChange={handleCountryChange} value={selectedCountry}>
															<option value="riyadh">{t('vacancyriyadh.location')}</option>
															<option value="jeddah">{t('vacancyjeddah.location')}</option>
														</select>
													</h4>
												</div>
											

												{selectedCountry === 'riyadh' ? riyadhContent : jeddahContent} {/* Display content based on selection */}

												<div className='displayNone'>
													<h4>{t('career.job-title')}</h4>
													<p><strong>{t('career.position')}</strong> {t('career.positiontext')}</p>
													<p><strong>{t('career.exp')}</strong> {t('career.exptext')}</p>
													<p><strong>{t('career.loc')}</strong> {t('career.loctext')}</p>
													<p><strong>{t('career.quali')}</strong> {t('career.qualitext')}</p>
													<p><strong>{t('career.jobdesc')}</strong> {t('career.jobdesctext')}</p>
													<p><strong>{t('career.key')}</strong> {t('career.keytext')}</p>
													<ul className="list-check">
														<li>{t('career.list1')}</li>
														<li>{t('career.list2')}</li>
														<li>{t('career.list3')}</li>
														<li>{t('career.list4')}</li>
														<li>{t('career.list5')}</li>
														<li>{t('career.list6')}</li>
													</ul>
													<p><strong>{t('career.skills')}</strong> {t('career.skillstext')}</p>
													<a href="#crform" className="btn btn-primary mt-10">{t('career.apply')}</a>
												</div>
											</div>
										</div>
										
										<div id='crform'>
											<JobApplicationForm />
										</div>
									</div>
								</div>
								<div className="col-lg-4 col-md-5 mb-20">
									<aside className="sticky-top">
										
										
										<WidgetContact />
										
									</aside>
								</div>
							</div>
							
						</div>
					</div>
				
				</div>
				
				<Footer />
				
			</>
		);
}

export default JobCareer;