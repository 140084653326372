import React, {Component, useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Layout
import Header from "../layout/header";
import Footer from "../layout/footer";

// Images
import bnrImg from "../../images/banner/booking.jpg";
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import { Helmet } from 'react-helmet';

function Booking(){

	const { t } = useTranslation();

	const history = useHistory();
	const handleSubmit = async (event) => {
			event.preventDefault();
			const formData = new FormData(event.target);

			const formDataObj = Object.fromEntries(formData.entries());

			const response = await fetch('https://swissemail.vercel.app/api/booking', {
				method: 'POST', // You may want to change the method depending on your server's requirements
				headers: {
				  'Content-Type': 'application/json',
				},
				body: JSON.stringify({
					...formDataObj,
					date: new Date(), // Add any additional fields here
				  }),
			  });

			// Send the data to the Google Apps Script
			/* const response = await fetch(
			"https://script.google.com/macros/s/AKfycbyU_GwGzwG5Ng8sxC93-NzyMv5nDAGODew9FubY1o8Yqtkh1vVHR2M4HCoRv4VSNXyj2Q/exec",
			{
				method: "POST",
				body: formData,
			}
			); */

			// Handle the response if needed
			if (response.ok) {
			// Successful submission
			console.log("Form submitted successfully");
			//   alert("Form submitted")
			history.push('/thankyou');
			
			event.target.reset();
			// Reset the form or perform other actions
			} else {
			// Handle errors
			console.error("Form submission failed");
			}
	}
		return (
			<>

				
				<Header />
				
				<div className="page-content bg-white">
					
					<div className="page-banner ovbl-dark" style={{backgroundImage: "url("+bnrImg+")"}}>
						<div className="container">
							<div className="page-banner-entry text-center">
								<h1><span>{t('footer-section.booking')}</span></h1>
								<nav aria-label="breadcrumb" className="breadcrumb-row">
									<ul className="breadcrumb">
										<li className="breadcrumb-item"><Link to="/"><i className="las la-home"></i>{t('header-area.home')}</Link></li>
										<li className="breadcrumb-item active" aria-current="page">{t('footer-section.booking')}</li>
									</ul>
								</nav>
							</div>
						</div>
					</div>
					
					<section className="section-area section-sp1 bg-light">
						<div className="container">
							<div className="row">
								<div className="col-lg-8 col-md-7 mb-30 mb-md-50">
									<form className="booking-form" onSubmit={handleSubmit} >
										<div className="row">
											<div className="col-lg-4">
												<div className="form-group">
													<h6 className="form-title">{t('bookingapp.vehicleYear')}</h6>
													<div className="input-group">
														<input type="text" name='year' className="form-control" placeholder='2008' required/>
													</div>
												</div>
											</div>
											<div className="col-lg-4">
												<div className="form-group">
													<h6 className="form-title">{t('bookingapp.make')}</h6>
													<div className="input-group">
														<select className="form-control" name='make' required>
															<option value="" selected="selected">{t('bookingapp.choose')}</option>
															<option value="Bentley">{t('bookingapp.Bentley')}</option>
															<option value="jaguar">{t('bookingapp.jaguar')}</option>
															<option value="range-rover">{t('bookingapp.range-rover')}</option>
															<option value="mini-cooper">{t('bookingapp.mini-cooper')}</option>
															<option value="rolls-Royce">{t('bookingapp.rolls-Royce')}</option>
															<option value="audi">{t('bookingapp.audi')}</option>
															<option value="bmw">{t('bookingapp.bmw')}</option>
															<option value="mercedes">{t('bookingapp.mercedes')}</option>
															<option value="porache">{t('bookingapp.porsche')}</option>
															<option value="volkswagen">{t('bookingapp.volkswagen')}</option>
															<option value="ferrari">{t('bookingapp.ferrari')}</option>
															<option value="lamborghini">{t('bookingapp.lamborghini')}</option>
															<option value="maserati">{t('bookingapp.maserati')}</option>
															<option value="cadillac">{t('bookingapp.cadillac')}</option>
															<option value="chevrolet">{t('bookingapp.chevrolet')}</option>
															<option value="dodge">{t('bookingapp.dodge')}</option>
															<option value="ford">{t('bookingapp.ford')}</option>
															<option value="gmc">{t('bookingapp.gmc')}</option>
															<option value="jeep">{t('bookingapp.jeep')}</option>
															<option value="infinitty">{t('bookingapp.infiniti')}</option>
															<option value="lexus">{t('bookingapp.lexus')}</option>
															<option value="pagani">{t('bookingapp.pagani')}</option>
															<option value="bugatti">{t('bookingapp.bugatti')}</option>
															<option value="mclaren">{t('bookingapp.mclaren')}</option>
															<option value="maybach">{t('bookingapp.maybach')}</option>
														</select>
													</div>
												</div>
											</div>
											<div className="col-lg-4">
												<div className="form-group">
													<h6 className="form-title">{t('bookingapp.mileage')}</h6>
													<div className="input-group">
														<input type="number" name='mileage' className="form-control" required/>
													</div>
												</div>
											</div>
											<div className="col-lg-12">
												<div className="form-group">
													<h6 className="form-title">{t('bookingapp.services')}</h6>
													<div className="input-group">
														<select className="form-control" name='service' required>
															<option value="" selected="selected">{t('bookingapp.choose')}</option>
															<option value="Axle/Drive Shaft Repair">{t('home-schedule-section.axle')}</option>
															<option value="Brake Repair">{t('home-schedule-section.brake')}</option>
															<option value="Engine Cooling System Repair">{t('home-schedule-section.engine-cooling')}</option>
															<option value="Gearbox Repair">{t('home-schedule-section.gearbox')}</option>
															<option value="Pre-Purchase Inspection">{t('home-schedule-section.pre-purchase')}</option>
															<option value="Suspension Repair">{t('home-schedule-section.suspension')}</option>
															<option value="Transmission Repair">{t('home-schedule-section.transmission')}</option>
															<option value="Touchless Wheel Alignment">{t('home-schedule-section.touchless-wheeel')}</option>
															<option value="AC Repair">{t('home-schedule-section.ac-repair')}</option>
															<option value="Camera / Radar Calibration">{t('home-schedule-section.camera')}</option>
															<option value="Car Software Repair & Programming">{t('home-schedule-section.car-software')}</option>
															<option value="Comfort System Repair">{t('home-schedule-section.comfort')}</option>
															<option value="Engine Control Unit Repair">{t('home-schedule-section.engine-control')}</option>
															<option value="Navigation Update">{t('home-schedule-section.navigation')}</option>
															<option value="Retrofit">{t('home-schedule-section.retrofit')}</option>
															<option value="Smart Body Repair">{t('home-schedule-section.smart-body')}</option>
															<option value="Car Detailing">{t('home-schedule-section.car-detailing')}</option>
															<option value="Car Tinting">{t('home-schedule-section.car-tinting')}</option>
															<option value="Car DIP Color">{t('home-schedule-section.car-color')}</option>
															<option value="Ceramic Coating">{t('home-schedule-section.ceramic-coating')}</option>
															<option value="Brake Caliper painting">{t('home-schedule-section.brake-caliper')}</option>
															<option value="Paint Protcetion Film">{t('home-schedule-section.paint-protection')}</option>
															<option value="WindShield Repair">{t('home-schedule-section.windshield')}</option>
															<option value="Classic Car Restoration">{t('home-schedule-section.classic-car')}</option>
															<option value="Car Recovery">{t('home-schedule-section.car-recovery')}</option>
															<option value="Fleet Maintenance">{t('home-schedule-section.fleet')}</option>
															<option value="Electric Car Repair">{t('home-schedule-section.electric-car')}</option>
														</select>
													</div>
												</div>
											</div>
											
											<div className="col-lg-12">
												<div className="form-group">
													<h6 className="form-title">{t('bookingapp.personal')}</h6>
												</div>
											</div>
											<div className="col-lg-6">
												<div className="form-group">
													<div className="input-group">
														<input name="name" type="text" className="form-control" placeholder={t('bookingapp.name')} required/>
													</div>
												</div>
											</div>
											<div className="col-lg-6">
												<div className="form-group">
													<div className="input-group">
														<input name="email" type="email" placeholder={t('bookingapp.email')} className="form-control" required/>
													</div>
												</div>
											</div>
											<div className="col-lg-6">
												<div className="form-group">
													<div className="input-group">
														<input name="phone" type="text" placeholder={t('bookingapp.phone')} className="form-control" required/>
													</div>
												</div>
											</div>
											<div className="col-lg-6">
												<div className="form-group">
													<div className="input-group">
														<input name="subject" type="text" required className="form-control"  placeholder={t('bookingapp.location')}/>
													</div>
												</div>
											</div>
											<div className="col-lg-12">
												<div className="form-group">
													<div className="input-group">
														<textarea name="message" rows="4" className="form-control" required  placeholder={t('bookingapp.message')} ></textarea>
													</div>
												</div>
											</div>
											<div className="col-lg-12">
												<button name="submit" type="submit" value="Submit" className="btn btn-primary btn-lg">{t('bookingapp.btn')}</button>
											</div>
										</div>
									</form>
								</div>
								<div className="col-lg-4 col-md-5 mb-30">
									<aside className="sticky-top">
										<div className="widget">
											<h5 className="widget-title">{t('bookingapp.youmayknow')}</h5>
											<ul className="service-menu style-1">
												<li><Link to="/aboutus">{t('header-area.about')} <i className="las la-question-circle"></i></Link></li>
												<li><Link to="/services">{t('header-area.ourservices')} <i className="las la-cog"></i></Link></li>
												<li><Link to="/careers">{t('bookingapp.job')} <i className="las la-user-plus"></i></Link></li>
												<li className="active"><Link to="/booking">{t('footer-section.booking')} <i className="lab la-wpforms"></i></Link></li>
											</ul>
										</div>
									</aside>
								</div>
							</div>
						</div>
					</section>
					
				</div>
				
				<Footer />
				
			</>
		);
}

export default Booking;